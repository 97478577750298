import { render, staticRenderFns } from "./HowItWorksSwap.vue?vue&type=template&id=a3810616&scoped=true&"
import script from "./HowItWorksSwap.vue?vue&type=script&lang=js&"
export * from "./HowItWorksSwap.vue?vue&type=script&lang=js&"
import style0 from "./HowItWorksSwap.vue?vue&type=style&index=0&id=a3810616&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3810616",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3810616')) {
      api.createRecord('a3810616', component.options)
    } else {
      api.reload('a3810616', component.options)
    }
    module.hot.accept("./HowItWorksSwap.vue?vue&type=template&id=a3810616&scoped=true&", function () {
      api.rerender('a3810616', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components-default/HowItWorksSwap.vue"
export default component.exports